import styled from 'styled-components'
import { cores } from '../../styles'

export const OffCanvasWhole = styled.div`
  border-radius: 8px 0 0 0;
`

export const OffCanvasHeader = styled.div`
  background-color: ${cores.corSecundaria};
  border-radius: 8px 0 0 0;
`

export const OffCanvasBody = styled.div`
  background-color: ${cores.corPrincipal};
`

export const LinksList = styled.ul`
  margin-top: 20px;

  li {
    margin-bottom: 20px;
  }
`

export const NestedLinksList = styled.ul`
  margin-left: 8px;
`
