import styled from 'styled-components'
import { cores } from '../../styles'

export const FormContainer = styled.div`
  background-color: ${cores.corPrincipal};
  padding: 24px;
  color: #fff;
  text-align: center;

  h2 {
    margin-top: 20px;
  }
`

export const ListContainer = styled.ul`
  padding-left: 0;
`

export const ListIconBlock = styled.div`
  padding: 2px;
  margin: 16px auto 8px;
  color: ${cores.corSecundaria};
`

export const ListItemBlock = styled.div`
  text-decoration: underline 1px;
`

export const ListItemFlex = styled.div`
  display: flex;
  justify-content: center;
`

export const ListSocialIcon = styled.div`
  padding: 24px 16px 8px;
`
