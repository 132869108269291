import * as S from './styles'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'
import { useState } from 'react'

const Avisos = () => {
  const [isHidden, setIsHidden] = useState(true)

  return (
    <>
      <S.QuadroAvisos id="avisos">
        <h1>Quadro de avisos</h1>
        <Global.Container>
          <S.CardAviso data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <S.BodyAviso>
              <Txt.TitleMd>Excursão do Minami Koen</Txt.TitleMd>
              <p>Clique para ler</p>
            </S.BodyAviso>
            <S.IconAviso>
              <img
                src=""
                alt=""
                height="25px"
                width="25px"
                className="imagem"
              />
            </S.IconAviso>
          </S.CardAviso>

          <S.ModalContainer
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-3" id="staticBackdropLabel">
                    Excursão ao Minami Koen
                  </h1>
                </div>
                <div className="modal-body">
                  <div>
                    <Txt.TitleMd>Data: Abril/2025 - Dia 23 - 09:00</Txt.TitleMd>
                    <Txt.TitleMd className="mt-4">Alimentação</Txt.TitleMd>
                    <Txt.TextMd>
                      Os alunos devem levar um bentô e um lanchinho da tarde com
                      opções de alimentação saudáveis e leves.
                    </Txt.TextMd>
                    <Txt.TitleMd className="mt-4">Cuidados</Txt.TitleMd>
                    <Txt.TextMd>
                      Se possível, enviar o aluno com tênis ou sapato fechado,
                      para se movimentar mais livremente durante as
                      brincadeiras, e não correr o risco de perder o calçado em
                      algum brinquedo.
                    </Txt.TextMd>
                    <Txt.TitleMd className="mt-4">Atenção</Txt.TitleMd>
                    <Txt.TextMd>
                      Aos pais que não fazem uso do transporte da escola,
                      pedimos para que levem o aluno até <b>no máximo 8:30</b>.
                      A van partirá da escola com os alunos às <b>9:00</b>,
                      portanto, quem chegar depois do horário estipulado, ficará
                      na escola realizando atividades.
                    </Txt.TextMd>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </S.ModalContainer>

          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className="page-item disabled">
                <a className="page-link">Voltar</a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  Próx.
                </a>
              </li>
            </ul>
          </nav>
        </Global.Container>
      </S.QuadroAvisos>
    </>
  )
}

export default Avisos
