import * as S from './styles'

const Header = () => {
  return (
    <>
      <S.HeaderBase>
        <img
          src="https://static-file-vault.vercel.app/cdtchechela/logo_cdtcc.svg"
          alt=""
          className="logo"
        />
        <S.MenuHamburger
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <S.MenuHamburgerLine></S.MenuHamburgerLine>
          <S.MenuHamburgerLine className="center"></S.MenuHamburgerLine>
          <S.MenuHamburgerLine></S.MenuHamburgerLine>
        </S.MenuHamburger>
      </S.HeaderBase>
    </>
  )
}

export default Header
