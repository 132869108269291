import * as S from './styles'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

const Carousel = () => {
  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <S.CarouselImageContainer className="carousel-item active">
            <S.CarouselImage
              src="https://escolaprisma.com.br/wp-content/uploads/2023/08/escola-infantil-particular-proporcionando-uma-educacao-de-qualidade-e-personalizada-para-o-seu-filho.jpg"
              className="d-block w-100"
              alt=""
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>First slide label</h5>
              <p>
                Some representative placeholder content for the first slide.
              </p>
            </div>
          </S.CarouselImageContainer>
          <S.CarouselImageContainer className="carousel-item">
            <S.CarouselImage
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIRxsb2zqRQzEByuAgK54c121_CxTEVtJLxg&s"
              className="d-block w-100"
              alt=""
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Second slide label</h5>
              <p>
                Some representative placeholder content for the second slide.
              </p>
            </div>
          </S.CarouselImageContainer>
          <S.CarouselImageContainer className="carousel-item">
            <S.CarouselImage
              src="https://novosalunos.com.br/wp-content/uploads/2019/06/255820-escola-infantil-o-que-avaliar-na-hora-de-escolher.jpg"
              className="d-block w-100"
              alt=""
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Third slide label</h5>
              <p>
                Some representative placeholder content for the third slide.
              </p>
            </div>
          </S.CarouselImageContainer>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  )
}

export default Carousel
