import styled from 'styled-components'

export const CarouselImage = styled.img`
  background-color: #c3c3c3;
  width: auto;
  height: 100%;
`

export const CarouselImageContainer = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;
`
