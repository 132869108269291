import styled from 'styled-components'
import { cores } from '../../styles'

export const QuadroAvisos = styled.div`
  background-color: #e2e2e2;
  text-align: center;
  padding: 16px 0;

  p {
    margin: 0;
    color: #fff;
  }
`

export const CardAviso = styled.div`
  color: #fff;
  background-color: ${cores.corPrincipal};
  margin: 8px 0;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 85% 15%;
  filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  transition: ease 0.3s;

  &:hover {
    background-color: ${cores.shadePrincipal};
  }

  .imagem {
    background-color: #fff;
  }
`

export const IconAviso = styled.div`
  background-color: ${cores.corSecundaria};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 12px 12px 0;
`

export const BodyAviso = styled.div`
  padding: 10px;
`

export const ModalContainer = styled.div`
  .modal-body {
    p {
      color: #1c1c1c;
    }
  }
`
