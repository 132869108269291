import { GlobalStyle } from './styles'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Home from './pages/Home'
import Construindo from './pages/Construindo'
import TrabalheConosco from './pages/TrabalheConosco'

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Construindo />
  },
  {
    path: '/dev',
    element: <Home />
  },
  {
    path: '/vagasDisponiveis',
    element: <TrabalheConosco />
  }
])

function App() {
  return (
    <>
      <GlobalStyle />
      <RouterProvider router={routes} />
    </>
  )
}

export default App
