import * as S from './styles'
import * as Txt from '../../styles/text'

const Sobre = () => {
  return (
    <>
      <S.QuadroSobre id="sobre">
        <Txt.TitleBig>Sobre nós</Txt.TitleBig>
        <img
          src="https://static-file-vault.vercel.app/cdtchechela/logo_cdtcc.svg"
          alt=""
          width="50%"
        />
        <Txt.TextMd>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero soluta
          aut in voluptates odit voluptatem porro beatae officiis praesentium
          nisi autem laboriosam totam iste, ex ipsa optio molestias accusantium
          sapiente.
        </Txt.TextMd>
        <S.BodyContent>
          <img
            src="https://imgs.search.brave.com/tTxzGGn9iXSULwNO4SN0ErzjEIn_Z0Y8y01mt5JUyyw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9ibG9n/LXZvb21wLnMzLnVz/LWVhc3QtMS5hbWF6/b25hd3MuY29tL3dw/LWNvbnRlbnQvdXBs/b2Fkcy8yMDIxLzA2/L3ByZWNpc29fZmF6/ZXJfZmFjdWxkYWRl/X3BhcmFfc2VyX3By/b2Zlc3Nvcl9kb19l/bnNpbm9fZnVuZGFt/ZW50YWwuanBn"
            alt=""
            width="100%"
          />
        </S.BodyContent>
        <S.AccordionContainer className="accordion" id="accordionAvisos">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <S.AccordionBtn
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Missão
              </S.AccordionBtn>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionAvisos"
            >
              <div className="accordion-body">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo
                quos voluptas molestiae, incidunt distinctio suscipit magnam
                corrupti numquam odit officiis, expedita, nobis neque veniam
                fugiat. Deleniti quasi animi quidem inventore!
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <S.AccordionBtn
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Visão
              </S.AccordionBtn>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionAvisos"
            >
              <div className="accordion-body">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Ducimus, esse consequatur eaque debitis soluta neque eum sequi
                adipisci provident necessitatibus voluptate officia tempora
                maxime expedita amet doloribus autem. Consequuntur, quidem?
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <S.AccordionBtn
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Valores
              </S.AccordionBtn>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionAvisos"
            >
              <div className="accordion-body">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint
                molestias illum est suscipit nesciunt mollitia consectetur ut
                omnis placeat rerum laborum a neque quas totam molestiae,
                repellat rem odit debitis.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <S.AccordionBtn
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Certificados
              </S.AccordionBtn>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionAvisos"
            >
              <div className="accordion-body">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint
                molestias illum est suscipit nesciunt mollitia consectetur ut
                omnis placeat rerum laborum a neque quas totam molestiae,
                repellat rem odit debitis.
              </div>
            </div>
          </div>
        </S.AccordionContainer>
      </S.QuadroSobre>
    </>
  )
}

export default Sobre
