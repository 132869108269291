import {
  FaInstagram,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneSquare,
  FaFacebook
} from 'react-icons/fa'

import Contato from '../../components/Contato'

import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'
import * as S from './styles'

const Construindo = () => {
  return (
    <>
      <S.ConstruindoContainer>
        <Global.Container>
          <S.Logo
            src="https://static-file-vault.vercel.app/cdtchechela/logo_cdtcc.svg"
            alt="logo"
          />
          <h1>Site em Desenvolvimento</h1>
          <Contato />
        </Global.Container>
      </S.ConstruindoContainer>
    </>
  )
}

export default Construindo
