import styled from 'styled-components'
import { cores } from '../../styles'

export const VagasContainer = styled.div`
  color: #fff;
  text-align: center;
  margin-top: 24px;

  .sectionTitle {
    margin-bottom: 36px;
  }
`

export const CardVaga = styled.div`
  margin-top: 24px;
`
