import styled from 'styled-components'

import { cores } from '../../styles'

export const QuadroSobre = styled.div`
  color: #fff;
  background-color: ${cores.corPrincipal};
  text-align: center;
  padding: 24px;

  /* h2 {
    margin-top: 24px;
  } */
`

export const BodyContent = styled.div`
  text-align: left;
  margin-top: 20px;
`

export const AccordionContainer = styled.div`
  margin-bottom: 20px;
`

export const AccordionBtn = styled.button`
  &:not(.collapsed) {
    background-color: ${cores.corSecundaria};
    color: #fff;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`
