import Header from '../../containers/Header'
import Turmas from '../../containers/Turmas'

import Menu from '../../components/Menu'
import Carousel from '../../components/Carousel'
import Avisos from '../../components/Avisos'
import Sobre from '../../components/Sobre'
import Contato from '../../components/Contato'

const Home = () => {
  return (
    <>
      <Header />
      <Menu />
      <Carousel />
      <Avisos />
      <Sobre />
      <Turmas />
      <Contato />
    </>
  )
}

export default Home
