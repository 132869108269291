import * as S from './styles'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'

const Turmas = () => {
  return (
    <>
      <S.QuadroTurmas id="turmas">
        <S.TabNavbar className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <S.TabSelector
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              <h6>Pré-escolar</h6>
            </S.TabSelector>
          </li>
          <li className="nav-item" role="presentation">
            <S.TabSelector
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              <h6>Ensino Fundamental</h6>
            </S.TabSelector>
          </li>
        </S.TabNavbar>
        <S.TabBody className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabIndex={0}
          >
            <Global.Container>
              <S.CardTurma>
                <S.CardTurmaStroke>
                  <img
                    src="https://via.placeholder.com/250x250"
                    alt=""
                    className="img_placeholder"
                  />
                  <Txt.TitleBig>Tico-tico</Txt.TitleBig>
                  <Txt.TextMd>X a Y anos</Txt.TextMd>
                  <div>
                    <Txt.TextBig>Habilidades esperadas:</Txt.TextBig>
                    <Txt.TextMd>
                      Capacidade de permanecer sentado, olhar nos olhos, etc...
                    </Txt.TextMd>
                  </div>
                  <div>
                    <Txt.TextBig>O que será desenvolvido:</Txt.TextBig>
                    <Txt.TextMd>
                      Coordenação motora básica, estimulos sensoriais, etc...
                    </Txt.TextMd>
                  </div>
                </S.CardTurmaStroke>
              </S.CardTurma>
            </Global.Container>
          </div>
          <div
            className="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabIndex={0}
          >
            <Global.Container>
              <S.CardTurma>
                <Txt.TitleBig>Ensino Fundamental</Txt.TitleBig>
                <Txt.TextMd>X a Y anos</Txt.TextMd>
                <div>
                  <Txt.TextBig>Habilidades esperadas:</Txt.TextBig>
                  <Txt.TextMd>
                    Capacidade de permanecer sentado, olhar nos olhos, etc...
                  </Txt.TextMd>
                </div>
                <div>
                  <Txt.TextBig>O que será desenvolvido:</Txt.TextBig>
                  <Txt.TextMd>
                    Coordenação motora básica, estimulos sensoriais, etc...
                  </Txt.TextMd>
                </div>
              </S.CardTurma>
            </Global.Container>
          </div>
        </S.TabBody>
        <S.LineDivision className="big" />
        <S.LineDivision />
        <S.LineDivision className="small" />
      </S.QuadroTurmas>
    </>
  )
}

export default Turmas
