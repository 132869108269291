import { useEffect, useState } from 'react'
import * as Txt from '../../styles/text'
import * as S from './styles'
import { useNavigate } from 'react-router-dom'

const Menu = () => {
  const [homePath, setHomePath] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    const url_origin = window.location.origin
    setHomePath(url_origin)
  }, [])

  document.addEventListener('hidden.bs.offcanvas', (event) => {
    const scrollY = window.scrollY
    setTimeout(() => window.scrollTo(0, scrollY), 0)
  })

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  const handleIdNav = (id: string, path?: string) => {
    if (path != undefined) {
      navigate(path)
      console.log('Navegando para ' + path)
      setInterval(() => {
        navigate(`#${id}`)
        console.log('Navegando para o id ' + id)
      }, 3000)
      console.log('Navegação de outra página concluída.')
    } else {
      navigate(`#${id}`)
    }
  }

  return (
    <>
      <S.OffCanvasWhole
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-bs-scroll="true"
      >
        <S.OffCanvasHeader className="offcanvas-header">
          <Txt.TextBig className="offcanvas-title" id="offcanvasRightLabel">
            Menu
          </Txt.TextBig>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </S.OffCanvasHeader>
        <S.OffCanvasBody className="offcanvas-body">
          <S.LinksList>
            <li>
              <a href="#">
                <Txt.TextBig>Inicio</Txt.TextBig>
              </a>
            </li>
            <li>
              <a href="/dev#avisos">
                <Txt.TextBig>Quadro de avisos</Txt.TextBig>
              </a>
            </li>
            <li>
              <a href={`#sobre`}>
                <Txt.TextBig>Sobre nós</Txt.TextBig>
              </a>
            </li>
            <li>
              <a href={`#turmas`}>
                <Txt.TextBig>Turmas</Txt.TextBig>
              </a>
            </li>
            <li>
              <a href={`#contato`}>
                <Txt.TextBig>Contato</Txt.TextBig>
              </a>
            </li>
            <li>
              <a href={`/vagasDisponiveis`}>
                <Txt.TextBig>Trabalhe conosco</Txt.TextBig>
              </a>
            </li>
          </S.LinksList>
        </S.OffCanvasBody>
      </S.OffCanvasWhole>
    </>
  )
}

export default Menu
