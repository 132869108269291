import { createGlobalStyle, styled } from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'

export const cores = {
  corPrincipal: '#741324',
  corPrincipalDown: '#510d19',
  shadePrincipal: '#c9213e',
  corSecundaria: '#dea210',
  corTerciaria: '#c5b39f',
  softSecundaria: 'rgba(222, 162, 16, 0.4)'
}

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${cores.corPrincipal};
  }

  * {
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 80vw;
  width: 100%;
`
