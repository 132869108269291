import Header from '../../containers/Header'
import Menu from '../../components/Menu'
import Vagas from '../../containers/Vagas'

import * as S from './styles'

const TrabalheConosco = () => {
  return (
    <S.PageContainer>
      <Header />
      <Menu />
      <Vagas />
    </S.PageContainer>
  )
}

export default TrabalheConosco
