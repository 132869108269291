import * as Txt from '../../styles/text'
import * as S from './styles'

const TrabalheFormModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Motorista de Transporte
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Txt.TextMd>
                Um texto breve descrevendo o que deverá ser feito ao ocupar a
                vaga.
              </Txt.TextMd>
              <Txt.TitleMd>Requisitos</Txt.TitleMd>
              <Txt.TextMd>
                Todos os requisitos desejados e/ou necessários para ocupar a
                vaga.
              </Txt.TextMd>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-bs-target="#staticBackdrop2"
                data-bs-toggle="modal"
              >
                Aplicar à vaga
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Formulário de inscrição
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Txt.TextMd>
                Preencha o formulário abaixo para cadastrar seus dados de
                contato, e vaga de preferência.
              </Txt.TextMd>
              {/* Formulario começa aqui */}
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Nome"
                />
                <label htmlFor="floatingInput">Nome Completo</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Nome"
                />
                <label htmlFor="floatingInput">Endereço</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="tel"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Nome"
                />
                <label htmlFor="floatingInput">Telefone</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Nome"
                />
                <label htmlFor="floatingInput">E-mail</label>
              </div>
              <S.FormCheckContainer>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Possuo experiência na área.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Não possuo experiência.
                  </label>
                </div>
                <div className="form-floating">
                  <textarea
                    className="form-control disable"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                  ></textarea>
                  <label htmlFor="floatingTextarea">Comments</label>
                </div>
              </S.FormCheckContainer>
              {/* Formulario termina aqui */}
              <Txt.TextSm>
                Assim que possível, entraremos em contato para dar
                prosseguimento ou não ao processo de seleção.
              </Txt.TextSm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" className="btn btn-success">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrabalheFormModal
