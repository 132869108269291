import styled from 'styled-components'
import { cores } from '../../styles'

export const HeaderBase = styled.header`
  background-color: ${cores.corPrincipal};
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;

  .logo {
    max-height: 100%;
  }
`

export const MenuHamburger = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  border: 3px solid ${cores.corSecundaria};
  background-color: transparent;
  display: flexbox;
  padding: 8px;

  .center {
    margin: 4px 0;
  }
`

export const MenuHamburgerLine = styled.div`
  width: 20px;
  height: 2px;
  background-color: ${cores.corSecundaria};
`
