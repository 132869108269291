import TrabalheFormModal from '../../components/TrabalheFormModal'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'
import * as S from './styles'

const Vagas = () => {
  return (
    <S.VagasContainer>
      <Global.Container>
        <Txt.TitleBig className="sectionTitle">
          Confira nossas vagas disponiveis:
        </Txt.TitleBig>
        <div className="card mb-3">
          <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
            Ainda disponível
          </span>
          <div className="card-body">
            <h5 className="card-title">Motorista de Transporte</h5>
            <p className="card-text">
              <small className="text-body-secondary">
                Vaga criada em: 00/00/0000
              </small>
            </p>
            <button
              className="btn btn-warning"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Ver detalhes da vaga
            </button>
          </div>
          <TrabalheFormModal />
        </div>
      </Global.Container>
    </S.VagasContainer>
  )
}

export default Vagas
