import {
  FaInstagram,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneSquare,
  FaFacebook
} from 'react-icons/fa'

import * as S from './styles'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'

const Contato = () => {
  return (
    <>
      <S.FormContainer id="contato">
        <Global.Container>
          <Txt.TitleBig>Contato</Txt.TitleBig>
          <div>
            <S.ListContainer>
              <li>
                <S.ListItemBlock>
                  <S.ListIconBlock>
                    <FaPhoneSquare size={30} />
                  </S.ListIconBlock>
                  <a href="tel:+8109064642904">
                    <Txt.TextMd>+81 090 6464-2904</Txt.TextMd>
                  </a>
                </S.ListItemBlock>
              </li>
              <li>
                <S.ListItemBlock>
                  <S.ListIconBlock>
                    <FaMapMarkerAlt size={30} />
                  </S.ListIconBlock>
                  <a href="https://maps.app.goo.gl/bTtVrwnYNnpt4fY76">
                    <Txt.TextMd>
                      Teramae-33 Akashibucho, Okazaki, Aichi 444-0241
                    </Txt.TextMd>
                  </a>
                </S.ListItemBlock>
              </li>
              <li>
                <S.ListItemBlock>
                  <S.ListIconBlock>
                    <FaEnvelope size={30} />
                  </S.ListIconBlock>
                  <a href="mailto:contato@cantinhodatiachechela.com">
                    <Txt.TextMd>contato@cantinhodatiachechela.com</Txt.TextMd>
                  </a>
                </S.ListItemBlock>
              </li>
              <li>
                <S.ListItemFlex>
                  <a
                    href="https://www.facebook.com/tiachechela/?_rdr"
                    className="flex-icon"
                  >
                    <S.ListSocialIcon>
                      <FaFacebook size={35} className="margin" />
                    </S.ListSocialIcon>
                  </a>
                  <a href="https://www.instagram.com/cantinhodatiachechela/">
                    <S.ListSocialIcon>
                      <FaInstagram size={35} />
                    </S.ListSocialIcon>
                  </a>
                </S.ListItemFlex>
              </li>
            </S.ListContainer>
          </div>
          {/* <form action="submit">
            <label htmlFor="nome">Nome</label>
            <input type="text" className="form-control" name="nome" />
            <input type="checkbox" name="" id="" />
          </form> */}
        </Global.Container>
      </S.FormContainer>
    </>
  )
}

export default Contato
